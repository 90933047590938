import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import mustache from 'mustache';

import {scrollToTop} from "./utils";

import {soil} from "./scenes/soil";
import {intro} from "./scenes/intro";
import {underground} from "./scenes/underground";

// @ts-ignore
import beansSprite from '../img/shrubs/beans.png';
// @ts-ignore
import spritePumpkin from '../img/shrubs/pumpkin.png';
// @ts-ignore
import spriteKale from '../img/shrubs/kale.png';
// @ts-ignore
import spriteCorn from '../img/shrubs/corn.png';
// @ts-ignore
import spritePear from '../img/shrubs/pear.png';
// @ts-ignore
import spriteAmaranth from '../img/shrubs/amaranth.png';
// @ts-ignore
import spriteCollard from '../img/shrubs/collard.png';
// @ts-ignore
import spriteTransparent from '../img/transparent.png';

gsap.registerPlugin(ScrollTrigger);

scrollToTop();


// @ts-ignore
const generateArray = (name, image = spriteTransparent, arr) => {
  return arr.map((item) => ({name, image: item ? image : spriteTransparent}))
};

const shrubs = {
  shrubSections: [
    {
      name: 'beans',
      shrubs: generateArray('beans', beansSprite, [
        0, 1, 1, 1,
        1, 1, 1, 1,
        1, 1, 1, 1,
        1, 1, 1, 1,
        1, 1, 1, 1,
      ]),
      image: beansSprite
    },
    {
      name: 'pumpkin',
      shrubs: generateArray('pumpkin', spritePumpkin, [
        1, 1, 1, 1,
        1, 1, 1, 1,
        1, 1, 1, 1,
        1, 1, 1, 1,
      ]),
      image: spritePumpkin
    },
    {
      name: 'kale',
      shrubs: generateArray('kale', spriteKale, [
        0, 1, 1, 1, 1,
        1, 1, 1, 1, 1,
        1, 1, 1, 1, 1,
        1, 1, 1, 1, 1,
        1, 1, 1, 1, 1,
      ]),
      image: spriteKale
    },
    {
      name: 'corn',
      shrubs: generateArray('corn', spriteCorn, [
        0, 1, 1, 1, 0,
        1, 1, 1, 1, 1,
        1, 1, 1, 1, 1,
        1, 1, 1, 1, 0,
        1, 1, 0, 0, 0,
        0, 1, 0, 0, 0
      ]),
      image: spriteCorn
    },
    {
      name: 'pear',
      shrubs: generateArray('pear', spritePear, [
        0, 1, 1,
        1, 1, 1,
        1, 1, 1,
        1, 1, 1,
        1, 1, 1,
      ]),
      image: spritePear
    },
    {
      name: 'amaranth',
      shrubs: generateArray('amaranth', spriteAmaranth, [
        1, 1, 1,
        1, 1, 1,
        1, 1, 1,
        0, 1, 1,
        0, 1, 1,
      ]),
      image: spriteAmaranth
    },
    {
      name: 'collard',
      shrubs: generateArray('collard', spriteCollard, [
        1, 1, 1,
        1, 1, 1,
        1, 1, 1,
        1, 1, 1,
      ]),
      image: spriteCollard
    },
  ]
}

const drawShrubs = () => {
  const template = document.getElementById("shrubs-template").innerHTML;
  document.getElementById('shrubbery').innerHTML = mustache.render(template, shrubs);
}

document.addEventListener("DOMContentLoaded", function () {
  // Rendering
  drawShrubs();

  // Animations
  intro.init();
  soil.init();
  underground.init();

  const body = document.getElementById("body");
  const preloader = document.getElementById("preloader");

  window.onload = function () {
    body.className = 'enable-scroll';
    preloader.className = 'preloader fade-out';

    setTimeout(() => {
      preloader.className = 'preloader fade-out hide';
    }, 1000)

    // Resize Event
    let windowWidth = window.innerWidth
    window.addEventListener("resize", function () {
      if (window.innerWidth != windowWidth) window.location.reload();
    })
  }
});


