import {gsap} from "gsap";

const _animations = {
  textEnter: () => {
    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: ".shrub-beans",
        start: "top bottom",
        endTrigger: '.shrub-kale',
        end: "top 40%",
        scrub: 0.5
      },
    });
    return timeline;
  },
  leftBadger: () => gsap.fromTo("#soil-badger-left", {
    x: -40,
  }, {
    scrollTrigger: {
      trigger: "#soil-badger-left",
      end: "top top", // when the top of the trigger hits the top of the viewport
      scrub: 0.5,
    },
    x: 0,
    ease: 'circ.out'
  }),
  middleBadger: () => gsap.fromTo("#soil-badger-middle", {
    x: 40,
  }, {
    scrollTrigger: {
      trigger: "#soil-badger-middle",
      end: "top top", // when the top of the trigger hits the top of the viewport
      scrub: 0.5, //
    },
    x: 0,
    ease: 'circ.out'
  }),
  rightBadger: () => gsap.fromTo("#soil-badger-right", {
    x: -40,
  }, {
    scrollTrigger: {
      trigger: "#soil-badger-middle",
      end: "top top", // when the top of the trigger hits the top of the viewport
      scrub: 0.5, //
    },
    x: 0,
    ease: 'circ.out'
  }),
  flungDirtLeft: () => {
    const start = {
      x: 100,
      y: 40,
      opacity: 0,
      scale: 0.7,
    }
    const end = {
      x: -20,
      y: -120,
      rotate: 90,
      opacity: 1,
      scale: 0.9,
    }
    const scrollTrigger = {
      trigger: "#l-dirt-1",
      end: "bottom top", // when the top of the trigger hits the top of the viewport
      scrub: 2, //
    }

    gsap.fromTo("#l-dirt-1", {
      ...start,
    }, {
      scrollTrigger,
      ...end,
      rotate: 20,
    })

    gsap.fromTo("#l-dirt-2", {
      ...start,
    }, {
      scrollTrigger,
      ...end,
      rotate: 50,
    })

    gsap.fromTo("#l-dirt-3", {
      ...start,
    }, {
      scrollTrigger,
      ...end,
      rotate: 90,
    })
  },
  flungDirtRight: () => {
    const start = {
      x: -20,
      y: -60,
      opacity: 0,
      scale: 0.2,
    }
    const end = {
      x: 100,
      y: -220,
      rotate: 90,
      opacity: 1,
      scale: 0.9,
    }
    const scrollTrigger = {
      trigger: "#r-dirt-1",
      end: "bottom top", // when the top of the trigger hits the top of the viewport
      scrub: 2, //
    }

    gsap.fromTo("#r-dirt-1", {
      ...start,
    }, {
      scrollTrigger,
      ...end,
      rotate: 20,
    })

    gsap.fromTo("#r-dirt-2", {
      ...start,
    }, {
      scrollTrigger,
      ...end,
      rotate: 50,
    })

    gsap.fromTo("#r-dirt-3", {
      ...start,
    }, {
      scrollTrigger,
      ...end,
      rotate: 90,
    })
  }
}


export const soil = {
  init: () => {
    _animations.textEnter();
    _animations.leftBadger();
    _animations.middleBadger();
    _animations.rightBadger();
    _animations.flungDirtLeft();
    _animations.flungDirtRight();
  }
}


