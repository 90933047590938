import {gsap} from "gsap";
import MotionPathPlugin from "gsap/MotionPathPlugin";
import TweenVars = gsap.TweenVars;

const _create = {
  beePath: (i: number, tweenVars?: TweenVars) => {
    gsap.set(`#insect-${i}`, {scale: 0.75, autoAlpha: 1});
    gsap.to(`#insect-${i}`, {
      duration: 15,
      ease: 'none',
      immediateRender: false,
      motionPath: {
        path: `#path-elliptical-${i}`,
        align: `#path-elliptical-${i}`,
        alignOrigin: [0.5, 0.5],
        autoRotate: i === 3 ? 90 : 45
      },
      repeat: -1,
      ...tweenVars
    });
  }
};

const _animations = {
  bees: () => {
    gsap.registerPlugin(MotionPathPlugin);
    _create.beePath(1, {animationDelay: 2, duration: 13});
    _create.beePath(2, {duration: 15});
    _create.beePath(3, {animationDelay: 4, duration: 17});
  },

  text: () => {
    const timeline = gsap.timeline();
    timeline.to(".bee", {
      scale: 0,
      duration: 3,
    });
    timeline.fromTo("#grateful-text", {translateX: -20, opacity: 0}, {
      translateX: 0,
      opacity: 1,
      duration: 4,
      ease: "power4.inOut"
    }, 1);
    timeline.fromTo("#opportunity-text", {translateX: 20, opacity: 0}, {
      translateX: 0,
      opacity: 1,
      duration: 4,
      ease: "power4.out"
    }, 2);
    timeline.fromTo("#grow-great-things", {translateY: 25, opacity: 0}, {
      translateY: 0,
      opacity: 1,
      duration: 2
    }, '<+=1');
    return timeline;
  },
  badgers: () => {
    const timeline = gsap.timeline();
    timeline.fromTo("#intro-badger-left", {rotate: 30, y: 500}, {
      y: 0,
      rotate: 0,
      duration: 1,
      ease: "circ.out",
    }, 0);
    timeline.fromTo("#intro-badger-middle", {rotate: -30, y: 500}, {
      y: 0,
      rotate: 0,
      duration: 1,
      ease: "circ.out",
    }, 0);
    timeline.fromTo("#intro-badger-right", {y: 200}, {y: 0, duration: 2.5, ease: "circle.out",}, 0);
    return timeline;
  },
  stalk: () => {
    const timeline = gsap.timeline();
    timeline.fromTo('.stalkTopLayer', {scale: 0.8}, {
      scale: 1,
      duration: 1
    });
    return timeline;
  },
  loadingAnimation: () => {
    const r = 60;
    gsap.to('.preloader-bee', {
      motionPath: {
        path: `M ${-r}, 0
           a ${r},${r} 0 1,0 ${r * 2},0
           a ${r},${r} 0 1,0 -${r * 2},0z`,
        autoRotate: 180
      },
      duration: 3,
      repeat: -1,
      ease: "none"
    });
  }
}

export const intro = {
  init: () => {
    _animations.bees();

    const textTimeline = gsap.timeline(
      {
        scrollTrigger: {
          trigger: ".intro-heading",
          endTrigger: '#stalk-tops',
          end: "top 40%",
          scrub: 0.5,
          pin: true
        },
      }
    );
    textTimeline.addLabel('intro');
    textTimeline.add(_animations.text());

    const stalkTimeline = gsap.timeline(
      {
        scrollTrigger: {
          trigger: "#stalk-tops",
          start: "top bottom",
          scrub: 0.5,
        },
      }
    );

    stalkTimeline.add(_animations.stalk());
    stalkTimeline.add(_animations.badgers(), "<+=-1");

    gsap.to(['#scroll-indicator', '.logo'], {
      opacity: 0,
      y: -100,
      scrollTrigger: {
        trigger: '#body',
        start: 'top top',
        end: "+=200",
        scrub: true,
      }
    })
    _animations.loadingAnimation();
  }
}





