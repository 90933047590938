import {gsap} from "gsap";
import $ from 'cash-dom';
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {getVertDiff, randomIntFromInterval} from "../utils";


const groups = {
  text: () => {
    const isMobile = window.innerWidth <= 480;
    ['.soil-heading-1', '.soil-heading-2', '.soil-heading-3', '.soil-heading-4',].forEach(heading =>
      gsap.fromTo(heading,
        {opacity: 0, y: isMobile ? 0 : -100},
        {
          opacity: 1,
          y: 0,
          scrollTrigger: {
            trigger: heading,
            scrub: 1.5,
          },
          start: "top bottom+=300px",
        }))
  },
  badgerPlowing: () => gsap.fromTo
  ("#badger-plowing", {}, {
    scrollTrigger: {
      trigger: "#badger-plowing",
      start: "top 50%",
      end: "bottom 0",
      scrub: 3,
    },
    y: window.innerHeight * (innerWidth <= 768 ? 0.28 : 0.5),
    ease: "power2.out"
  }),
  badgerPlowingRight: (innerWidth?: number) => gsap.fromTo("#badger-plowing-right", {
    y: 0,
  }, {
    scrollTrigger: {
      trigger: '.shrub-corn',
      start: "bottom bottom",
      scrub: true,
    },
    ease: "power2.inOut",
    y: getVertDiff($('.shrub-pear'), $('.shrub-kale')) * (innerWidth <= 480 ? 0.25 : 0.33),
    duration: 4
  }),
  badgerPlanting: () => gsap.fromTo("#badger-planting", {}, {
    scrollTrigger: {
      trigger: "#badger-planting",
      start: "top center",
      end: "bottom top",
      scrub: 2,
    },
    y: 50,
  }),
  badgerSplooting: (innerWidth?: number) => gsap.fromTo("#badger-splooting", {
    scale: 0.95,
    y: window.innerHeight * (innerWidth <= 768 ? 0.15 : 0.28),
  }, {
    scrollTrigger: {
      trigger: '.shrub-pumpkin',
      start: "bottom bottom",
      scrub: true,
    },
    ease: "power2.inOut",
    y: 0,
    scale: 1,
    duration: 4
  }),
  shrubbery: () => {
    gsap.set(".shrub", {scale: 0.3, rotate: 'random(0, 360)'});

    ScrollTrigger.batch(".shrub", {
      start: "top bottom-=100px",
      onEnter: batch => gsap.to(batch, {
        scale: 1,
        rotate: randomIntFromInterval(0, 180),
        stagger: { // wrap advanced options in an object
          each: 0.15,
          from: "random",
          ease: "back.out",
          amount: 0.4
        }
      }),
      onLeaveBack: batch => gsap.to(batch, {scale: 0, stagger: 0.1})
    });
  },
  trees: () => {
    gsap.set(".tree", {scale: 0.3,});

    ScrollTrigger.batch(".tree", {
      start: "top bottom-=100px",
      onEnter: batch => gsap.to(batch, {
        scale: 1,
        rotate: randomIntFromInterval(0, 50),
        stagger: { // wrap advanced options in an object
          each: 0.15,
          from: "random",
          ease: "back.out",
          amount: 0.4
        }
      }),
      onLeaveBack: batch => gsap.to(batch, {scale: 0, stagger: 0.1})
    });
  },
  finalScene: () => {
    gsap.to(".final-scene-left", {
      x: -25,
      y: 25,
      scale: 0.9,
      ease: "none",
      scrollTrigger: {
        trigger: ".final-scene-left",
        start: "top bottom", // the default values
        end: "bottom top",
        scrub: true
      },
    });

    gsap.to(".final-scene-right", {
      x: 25,
      y: 25,
      scale: 0.9,
      ease: "none",
      scrollTrigger: {
        trigger: ".final-scene-right",
        start: "top bottom", // the default values
        end: "bottom top",
        scrub: true
      },
    });

    gsap.fromTo("#badgers-finish",
      {
        y: -100,
        scale: 0.8,
      },
      {
        y: 50,
        scale: 1,
        ease: "none",
        scrollTrigger: {
          trigger: ".final-scene-right",
          start: "top bottom", // the default values
          end: "bottom center",
          scrub: true
        },
      });

    gsap.fromTo(".soil-heading-5",
      {
        opacity: 0,
      },
      {
        opacity: 1,
        ease: "none",
        scrollTrigger: {
          trigger: ".soil-heading-5",
          start: "top bottom+=300px",
        },
      });
  }
}

export const underground = {
  init: () => {
    const {innerWidth} = window;

    groups.text();
    groups.badgerPlowing();
    groups.badgerPlowingRight(innerWidth);
    groups.badgerPlanting();
    groups.badgerSplooting(innerWidth);

    groups.shrubbery();
    groups.trees();
    groups.finalScene();

  }
}


